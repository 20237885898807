import './livemap.css';
import * as signalR from '@microsoft/signalr';
import { loadScript } from '../../utility';

export class Livemap {
    private connection : signalR.HubConnection;    
    private controllerPositions : google.maps.MVCArray<google.maps.LatLng>;
    private map : HTMLElement;
    
    detached() {
        if (!this.connection)
            return;

        console.log('Hub stopping');
        this.connection.stop().then(function () {        
            console.log('Hub connection stopped');
        }).catch(function (err) {
            console.error('Hub stop error: ', err.toString());
        });  
    }

    async attached() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const myThis = this;                
        
        await this.initMap();

        this.connection  = new signalR.HubConnectionBuilder()
            .withUrl("/hubs/liveMapHub", { 
                headers: {
                    'X-CSRF': "1",
                  }
             })
            .withAutomaticReconnect()            
            .build();
        
        this.connection.on("ReceiveMessage", function (message) {
            let controller = JSON.parse(message);            
            if (controller.Lat && controller.Lng) {        
                
                //Keep only n-position elements
                for (let i = 0; i < myThis.controllerPositions.getLength() && myThis.controllerPositions.getLength() > 100; i++) {
                    myThis.controllerPositions.removeAt(i);
                }
        
                myThis.controllerPositions.push(new google.maps.LatLng(controller.Lat, controller.Lng));
            }
        });
        
        console.log('Hub starting');
        this.connection.start().then(function () {        
            console.log('Hub started');
        }).catch(function (err) {
            console.error('Hub start error: ', err.toString());
        });                        
    }

    async initMap() {
        //Load google maps with visualization library
        await loadScript(
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyD5d7KDmuxUcVlk-SBq9_zfGFT0JKyJnV0&libraries=visualization'
          )

        this.controllerPositions = new google.maps.MVCArray([]);
        
        const googlemap = new google.maps.Map(this.map, {
            zoom: 5,
            center: { lat: 53.8490759, lng: 14.2801113 },
            mapTypeId: 'hybrid'
        });

        new google.maps.visualization.HeatmapLayer({
            data: this.controllerPositions,
            radius: 20,
            opacity: 0.8,            
            map: googlemap
        });
    }
}